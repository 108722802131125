.wrapper {
  margin: 0 10% 0 0;
}

.articleText {
  font-family: "Source Sans Pro", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  padding-left: 56%;
  word-wrap: break-word;
  margin: 5rem 0;
}

.articleTextComment {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  padding-left: 61%;
  font-size: 32px;
  line-height: 32px;
  margin: 5rem 0;
}

.articleImageFull {
  padding-left: 0;
  width: 100%;
  margin: 5rem 0;
  border-radius: 10px;
}

.articleImage {
  width: 40%;
  margin: 5rem 0 5rem 56%;
  border-radius: 10px 10px 10px 10px;
}

.additionInfo {
  margin-left: 5%;
  border-top: 4px solid #ff8e00;
  border-bottom: 4px solid #ff8e00;
}

.additionInfo_head {
  font-family: "Prosto One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  line-height: 120px;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.underHead {
  position: relative;
  margin: 2rem 0;
}

.additionInfo_headMini {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #ff8e00;
  width: 40%;
  margin-bottom: 8rem;
  padding-left: 10%;
  display: inline-block;
}

.additionInfo_img {
  float: right;
  width: 46%;
  margin-bottom: 5rem;
}

.descrioptionOfArticles {
  margin-top: 10rem;
}

.article_question {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  position: relative;
}

.articleTextNew {
  font-family: "Source Sans Pro", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  word-wrap: break-word;
  width: 100%;
  position: relative;
  display: inline-block;
}

.article_questionGroup {
  display: flex;
  flex-direction: column;
  float: left;
  width: 28%;
  padding-left: 12%;
  align-items: flex-start;
}

.article_questionText {
  float: left;
  padding-left: 12%;
  width: 40%;
}

.article_question_img {
  margin-top: 2rem;
  border-radius: 10px;
  width: 100%;
}

.article_question span {
  color: #ff8e00;
}

.article_question_cards {
  display: flex;
  flex-wrap: wrap;
  padding-left: 16%;
  padding-right: auto;
  margin-top: 3rem;
}

.article_question_card {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  width: auto;
  padding: 1rem 5rem 2rem 1rem;
  border-radius: 10px;
  margin-right: 1rem;
  margin-top: 1rem;
}

.cardText {
  font-size: 14px;
  line-height: 18px;
}

.cardNumber {
  font-weight: 400;
  font-size: 24px;
}

.article_question_cards2 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 56%;
  padding-right: auto;
  margin-top: 3rem;
}

.article_question_card2 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  width: 12rem;
  height: 13rem;
  padding: 2rem 2rem;
  border-radius: 10px;
  margin-right: 1rem;
  position: relative;
  margin-top: 3rem;
}

.cardNumber2 {
  background-color: #ff8e00;
  width: 60px;
  height: 60px;
  border-radius: 0 40px 40px 40px;
  border: 6px solid black;
  position: absolute;
  top: -2.5rem;
  left: 1rem;
  font-size: 24px;
  text-align: center;
  padding-top: 0.5rem;
}

.cardText2 {
  font-size: 14px;
  line-height: 18px;
  padding-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  width: 8rem;
}

.cardText3 {
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  text-transform: lowercase;
  padding-top: 1rem;
}

.article_question_table {
  border: 1px solid hsla(0, 0%, 100%, 0.4);
  width: 100%;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 2rem;
}

.cardTextTable {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.articleList {
  padding-left: 56%;
  width: 100%;
  margin: 5rem 0;
}

.smallList {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  word-wrap: break-word;
  margin: 2rem 0;
  width: 40%;
}

.bigList {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  list-style-position: inside;
  margin: 2rem 0;
  width: 40%;
}

.bigListTable {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  padding: 1rem 1rem;
  height: auto;
  margin: 2rem 0;
  width: 40%;
}

.adressTable {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  opacity: 0.6;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  width: 20%;
  margin-bottom: 1rem;
  padding: 3px;
}

.adress {
  font-size: 14px;
  line-height: 18px;
  word-wrap: break-word;
}

.articleTextBig {
  font-family: "Source Sans Pro", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  padding-left: 56%;
  word-wrap: break-word;
  margin: 5rem 0;
}

.cardPerson_group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.cardPerson {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  padding: 2rem 2rem;
  position: relative;
 
  display: inline-block;
  margin-bottom: 1rem;
}

.cardPerson_img {
  position: absolute;
  top: -3.5rem;
}

.cardPerson_name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 2rem 5rem 2rem 0;
}

.cardPerson_link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  width: 100%;
  padding: 1rem 5rem 3rem 0;
}

.buttonCard {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 10%;
  opacity: 50%;
  background-color: transparent;
  position: absolute;
  top: 10rem;
  left: 12rem;
}

.arrCard {
  background-image: url(../../../public/assets/icons/whitear.png);
  position: absolute;
  top: 11.5rem;
  left: 12rem;
  width: 26px;
  height: 18px;
  background-repeat: no-repeat;
  opacity: 50%;
  z-index: 1;
  background-position-x: -27px;
}

.infoHeader {
  border-top: 4px solid #ff8e00;
  border-bottom: 4px solid #ff8e00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  padding: 10rem 0 5rem;
}

.infoHeader_head {
  font-family: "Prosto One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 160px;
  line-height: 160px;
  text-transform: uppercase;
  margin: 3rem 0;
}

.infoHeader_headMini {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  color: #ff8e00;
  width: 30%;
}

.custom-counter {
  counter-reset: list-counter;
}
.bigList::before {
  content: counter(list-counter) ". ";
  counter-increment: list-counter;
}
.smallList::before {
  content: "3." counter(list-counter) " ";
  counter-increment: list-counter;
}

@media screen and (max-width: 800px) {
  .wrapper {
    margin: 0 0;
  }
  

  .articleText {
    font-size: 18px;
    line-height: 22px;
    padding-left: 0;
    margin: 1rem 0;
  }

  .articleTextComment {
    font-size: 20px;
    line-height: 20px;
    margin: 3rem 0;
    padding-left: 10%;
  }

  .articleImageFull {
    margin: 3rem 0;
    width: 100%;
  }

  .articleImage {
    margin: 3rem 0;
    width: 80%;
  }

  .additionInfo {
    margin-left: 0;
  }

  .additionInfo_head {
    font-size: 60px;
    line-height: 60px;
  }

  .additionInfo_headMini {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 5rem;
  }

  .descrioptionOfArticles {
    margin-top: 5rem;
  }

  .article_question {
    font-size: 24px;
    line-height: 24px;
  }

  .articleTextNew {
    font-size: 18px;
    line-height: 18px;
  }

  .article_questionGroup {
    float: none;
    padding-left: 0;
  }

  .article_questionText {
    margin-top: 2rem;
    float: inherit;
    padding-left: 0;
    width: 100%;
  }

  .article_questionText2 {
    width: 300%;
    margin-top: 2rem;
  }

  .article_question_img {
    width: 94vw;
  }

  .article_question_cards {
    padding-left: 0;
  }

  .article_question_cards2 {
    padding-left: 0;
  }

  .articleTextBig {
    padding-left: 0;
  }

  .articleList {
    padding-left: 10%;
    width: 70%;
    margin: 5rem 0;
  }

  .bigList {
    width: 100%;
  }

  .bigListTable {
    width: 100%;
  }

  .smallList {
    width: 100%;
  } 
  .cardPerson_group {
    justify-content: center;
  }

  .cardPerson {
    margin-left: 0;
    margin-right: 1rem;
    
  }

  .cardPerson_loop {
    width: 100%;
  }

  .infoHeader {
    margin-left: 0;
    padding: 5rem 0 5rem;
  }

  .infoHeader_head {
    font-size: 100px;
    line-height: 80px;
    margin: 3rem 0;
  }

  .infoHeader_headMini {
    font-size: 22px;
    line-height: 22px;
    width: 50%;
  }
}

@media screen and (max-width: 480px) {

  .articleImage {
    padding-left: 0;
    width: 100%;
    margin: 2rem 0;
  }

  .additionInfo_head {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
  }

  .additionInfo_headMini {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  
  .additionInfo_img {
    float: inherit;
    width: 100%;
    margin-bottom: 0;
  }

  .infoHeader {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0;
  }

  .infoHeader_head {
    text-align: center;
    width: 100%;
    margin: 3rem 0;
  }

  .infoHeader_headMini {
    font-size: 20px;
    line-height: 20px;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
  }

  .articleTextBig {
    margin: 2rem 0;
  }

  .cardPerson {
    margin: 2rem 0;
  }
  .description {
    width: 100%;
  }
}
