@font-face {
  font-family: "Source Sans Pro";
  src: url(../../../public/fonts/SourceSansPro-Regular.ttf) format("truetype");
  font-weight: normal;
}

body {
  box-sizing: border-box;
  font-size: 1.8rem;
  font-family: "Source Sans Pro";
  background-color: #000000;
  color: #fff;
  cursor: default;
  
}