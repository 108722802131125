@import "/src/styles/style.scss";

.container {
max-width: $container;
} 

.buttons {
    display: flex;
    width: 50rem;
    justify-content: space-evenly;
    margin-top: 5rem;
    margin-right: 25%;
    margin-left: auto;

    &__buttonBase {
                width: 40%;
                height: 70px;
                border-radius: 40px;
                border: 1px solid #fff;
                background-color: rgba(255, 255, 255, 0);
                margin-top: 4rem;
                margin-right: 1.6rem;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 15px;
                color: white;
                cursor: pointer;

                &:hover {
                    background-color: rgba(255, 255, 255, 1);
                    color: #E9453A;
                  }
  
                  &:focus {
                      background-color: rgba(255, 255, 255, 1);
                      color: #E9453A;
                      }
        }
                  
    &__buttonBots {
                  width: 50%;
                  height: 70px;
                  border-radius: 40px;
                  border: 1px solid #fff;
                  background-color: rgba(255, 255, 255, 0);
                  margin-top: 4rem;
                  margin-right: 1.6rem;
                  font-weight: 400;
                  text-transform: uppercase;
                  font-size: 15px;
                  color: white;
                  cursor: pointer;
          
                  &:hover {
                  background-color: rgba(255, 255, 255, 1);
                  color: #E9453A;
                  }
  
                  &:focus {
                      background-color: rgba(255, 255, 255, 1);
                      color: #E9453A;
                      }
                      }
    }  


.mission {
    &__Triangle {
        position: absolute;
        top: 70rem;
        left: 48px;
        background-image: url(../../../public/assets/image/white-semicircle.png);
        width: 182px;
        height: 182px;
    }

    &__Name {
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 100px;
        line-height: 100px;
        text-transform: uppercase;
        max-width: 50rem;
        position: relative;
        margin-left: 15%;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    &__Figure {
        position: absolute;
        background-image: url(../../../public/assets/image/figure-circles.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 52px;
        height: 131px;
        right: 50px;
        top: 70rem;
    }
}

.market {
    border-top: 1px solid white;
}

.block {
    &__name {
        border-bottom: 1px solid white;
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        padding-bottom: 3rem;
    }


    &__square {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-direction: row;
        margin-bottom: 15rem;
        width: 100%;
        
        &__cards {
            border-top: 4px solid transparent;
            padding-top: 5rem;
            display: inline;
            padding: 2rem 0rem 0;      
            width: 23%;
            margin: 0 1% 5rem 1%;

            &:hover {
                border-top: 4px solid #FF8E00;

            }
            

            &--img {
                object-fit: cover;
                border-radius: 5%;
                margin-bottom: 2rem;
                cursor: pointer;
                overflow: hidden;
                width: 100%;
                height: 40rem;
            }

            &--group {
                display: flex;
                justify-content: space-between;
                position: relative;

                &--el1 {
                    &--head {
                        font-family: 'Source Sans Pro';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #FF8E00;
        
                    }
                    &--data {
                        font-family: 'Prosto One';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #999999;
                        margin-top: 1rem;
                    }
                }
                
                &--el2 {
                    
                    &--text {
                        font-family: 'Source Sans Pro';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: right;
                        text-transform: uppercase;
                        max-width: 20rem;
                        margin-right: 0;
                        margin-left: auto;
                        cursor: pointer;
                        position: relative;
                    }

                    &--arrow {
                        width: 54px;
                        height: 18px;
                        background-image: url(../../../public/assets/icons/orangear.png);
                        background-repeat: no-repeat;
                        top: 6rem;
                        right: 0;
                        position: absolute;
                        cursor: pointer;
                    }
                }

            }
            
            
        }
    }
}

.imgCard2 {
    height: 300px;
}

.imgCard3 {
    height: 500px;
}

.imgCard4 {
    height: 450px;
}


// .bord {
//     border-top: 1px solid #ffffff40;
//     position: relative;
//   }

.main {
    display: flex;
    justify-content: space-evenly;
    padding-top: 5rem;
    border-top: 1px solid white;
    position: relative;

    &__Head {

        max-width: 100rem;

        &--head {
            position: relative;
            font-family: 'Prosto One';
            font-style: normal;
            font-weight: 400;
            font-size: 200px;
            line-height: 90%;
            text-transform: uppercase;
            max-width: 100rem;
        }
        

        &--pic {
            position: absolute;
            background-image: url(../../../public/assets/image/Bitmap.png);
            background-position: center;
            background-repeat: no-repeat;
            width: 126px;
            height: 126px;
            right: 50%;
            top: -7rem;
            z-index: 5;
        }
    }

    &__Body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #999999;

        &__text {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &--small {
                text-align: left;
            }

            &--big {
                text-align: right;
                width: 40%;
            }
        }

        &__list {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            width: 100%;
            padding-left: 0;
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            text-transform: uppercase;
            color: #FFFFFF;
            align-items:flex-end;


            &__Left {
                list-style-type: none;
            
                &--li {
                    padding: 2rem 0;
                }
            }

            &__Center {
                list-style-type: none;
                color: #999999;

                &--li {
                    padding: 2rem 0;
                }
            }

            &__Right {
                text-align: right;
                list-style-type: none;
                width: 40%;
                
                &--li {
                    padding: 2rem 0;
                }
                
            }
        }
    }
}


.users {
    margin-bottom: 50rem;
    &__Card {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 15rem;
        margin-bottom: 10rem;

        
        &__Item {
            border: 1px solid gray;
            border-radius: 5%;
            width: 28rem;
            height: 24rem;
            position: relative;
            margin-right: 1rem;
            margin-top: 2rem;

            &--pic {
                position: absolute;
                background-image: url(../../../public/assets/image/avaic.png);
                border-radius: 0 35px 35px;
                background-position: center;
                background-repeat: no-repeat;
                width: 72px;
                height: 72px;
                right: 70%;
                top: -10%;

                &--2, &--3, &--4, &--5 {
                    position: absolute;
                    border-radius: 0 35px 35px;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 72px;
                    height: 72px;
                    right: 70%;
                    top: -10%;
                }
                &--2 {
                    background-image: url(../../../public/assets/image/bot2.png);
                }

                &--3 {
                    background-image: url(../../../public/assets/image/bot3.png);
                }

                &--4 {
                    background-image: url(../../../public/assets/image/bot4.png);
                }
                &--5 {
                    background-image: url(../../../public/assets/image/bot5.png);
                }
                
            }

            &--text {
                position: absolute;
                width: 80%;
                top: 25%;
                left: 10%;
                text-transform: uppercase;
                overflow-wrap: break-word;
            }

            &--line {
                position: absolute;
                width: 80%;
                border-top: 1px solid white;
                top: 40%;
                left: 10%;
                opacity: 50%;
                z-index: -1;
            }

            &--link {
                position: absolute;
                width: 80%;
                top: 50%;
                left: 10%;
                opacity: 50%;
            }

            &--button {
                position: absolute;
                width: 8rem;
                height: 8rem;
                top: 55%;
                left: 60%;
                background-color: transparent;
                border: 0.5px solid white;
                border-radius: 5px;
                opacity: 50%;
            }

            &--arr {
                position: absolute;
                color: #FFFFFF;
                width: 80%;
                top: 68%;
                left: 60%;
                background-image: url(../../../public/assets/icons/whitear.png);
                opacity: 50%;
                background-repeat: no-repeat;
                // z-index: -1;
            }
        }

    }
}

//media//


@media screen and (max-width:820px) {
    .buttons {
            width: 98vw;
            margin: 0 auto;
        
            &__buttonBase {
                width: 50%;
            }
        }  
        
    .mission {
            &__Triangle {
                display: none;
            }
        
            &__Name {
                font-size: 38px;
                line-height: 47px;
                top: 2rem;
                left: 0;
                margin-bottom: 2rem;
                margin-left: 0;
            }
        
            &__Figure {
                display: none;
            }
    }
        
        .block {
            margin-left: 1rem;
            &__name {
                border-bottom: 1px solid white;
                font-family: 'Prosto One';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                text-transform: uppercase;
                padding-bottom: 1rem;
            }


            &__square {
                margin-bottom: 5rem;
                

                &__cards {
                    // padding: 0 0;
                    width: 90vw;
                    margin: 0 0 2rem;
                    

                    &--img {
                        width: 90vw;
                        max-height: 40rem;
                    }
                    &--group {
                       

                        &--el2 {
        
                            &--arrow {
                                top: 6rem;
                            }
                        }
                    }
                }
            }
        }
 
        .main {
            flex-wrap: wrap;

            &__Head {
                max-width: 100rem;
        
                &--head {
                    font-size: 100px;
                    line-height: 80px;
                }
                
                &--pic {
                    left: 12.5rem;
                }
            }
        
            &__Body {
                flex-wrap: wrap;
                margin-top: 5rem;
            }
        }
        
        
    .users {
        margin-bottom: 5rem;

            &__Card {
                margin-top: 5rem;
                justify-content: space-between;
                &__Item {
                    margin-bottom: 5rem;
                    width: 18rem;
                    height: 19rem;
                    margin-right: 0;
                    
        
                    &--pic {
                        width: 72px;
                            height: 72px;
                            right: 50%;
                            top: -20%;
                        &--2, &--3, &--4, &--5 {
                            width: 72px;
                            height: 72px;
                            right: 50%;
                            top: -20%; 
                        }
                    }
        
                    &--line {
                        display: none;
                    }

                    &--link {
                        top: 65%;
                        font-size: 12px;
                        width: 40%;
                        word-wrap: break-word;
                    }

                    &--button {
                        width: 6rem;
                        height: 6rem;
                        top: 60%;
                        border-radius: 10px;
                    }
        
                    &--arr {
                        width: 4rem;
                        top: 71%;
                        left: 60%;
                        background-image: url(../../../public/assets/icons/whitear.png);
                        opacity: 50%;
                        background-repeat: no-repeat;
                        background-position-x: -2rem;
                    }
                }
        
            }
        }
        
}

@media screen and (max-width:414px) {
        .block {
            &__square {
                &__cards {
                    margin-bottom: 3rem;
                    &--img {
                        height: 30rem;
                    }
                    &--group {
                        &--el2 {
                            &--arrow {
                                top: 5rem;
                            }
                        }
                    }
                }
            }
        }     

        .users {
            margin-bottom: 5rem;

            &__Card {
                justify-content: space-around;
                margin-top: 5rem;

                &__Item {
                    width: 16rem;
                    height: 16rem;
                    margin-right: 0;
                    margin-top: 1rem;

                    &--text {
                        font-size: 16px;
                        top: 30%;
                    }
        
                    &--pic {
                        width: 62px;
                        height: 62px;
                        right: 60%;
                        top: -15%;
        
                        &--2, &--3, &--4, &--5 {
                            width: 62px;
                            height: 62px;
                            right: 60%;
                            top: -15%;
                        }
                    }
        
                    &--line {
                        top: 50%;
                    }
        
                    &--link {
                        top: 65%;
                        
                    }
        
                    &--button {
                        width: 5rem;
                        height: 5rem;
                        top: 60%;
                        left: 60%;
                    }
        
                    &--arr {
                        top: 71%;
                    }
                }
        
            }
}

}