@import "../../styles/style.scss";

.container {
@include container
}

.header-nav__People--photo {
  text-align: center;
  background-repeat: no-repeat;
  width: 64px;
  height: 64px;
  margin-left: 28rem;
  // z-index: 1;
  // top: 2rem;
  border-radius: 50%;
}

.header-nav { 
  border-bottom: 1px solid white;
  position: relative;
  padding: 3rem 0;
  display: flex;
  justify-content: space-between;

    &__Menu {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-start;
      width: 20rem;
      cursor: pointer;

    &--burger {
      max-width: 10rem;
      margin: 0 0;
      

      &--tab {
        backdrop-filter: blur(10px);
        position: absolute;
        color: red;
      }
    }

      &--menu {
        font-family: "Prosto One";
        font-size: 1.4rem;
        max-width: 10rem;
      }
    }

    &__People {
      

      &--logo-symbol {
        background-image: url(../../../public/assets/icons/logo-symbol.svg);
        position: absolute;
        background-repeat: no-repeat;
        width: 65px;
        height: 65px;
        z-index: 3;
        top: 2rem;
        right: 50.3%;
        background-size: cover;
        display: none;
      }
      &--oval {
        background-image: url(../../../public/assets/icons/Oval.png);
        position: absolute;
        background-repeat: no-repeat;
        width: 76px;
        height: 76px;
        z-index: 2;
        top: 1.5rem;
        right: 50%;
        display: none;
      }
    }

    &__Wallet {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      font-family: 'Prosto One';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;

      &__buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        
        
        &--button {
          width: 50%;
          padding: 1rem 3rem;
          height: 5rem;
          border: 1px solid grey;
          }
        
      }

        &--price {
          width: 20%;
          margin-left: 3rem;
          margin-right: 3rem;
          white-space: nowrap;
          color: aqua;
         

          &-span {
            // display: inline;
            border-bottom: 1px solid aqua;
            margin-right: 1rem;
            color: white;
          }
    }
    }
  }


@media screen and (max-width: 1100px) {
        .header-nav {
            &__Wallet {
              &__buttons {
                width: 16rem;
                justify-content: flex-end;

                &--button {
                  width: 15rem;
                  padding: 0.5rem 3rem;
                  height: 4rem;;
                  vertical-align: middle;
                  margin-bottom: 0.5rem;
                  }
  
              }
              &--price {
                margin-right: 0;
                    width: 10rem;
            }}
        }
}


@media screen and (max-width: 820px){
  .header-nav {
      &__People {
        &--photo {
          background-image: url(../../../public/assets/icons/avatar.png);
          position: absolute;
          background-repeat: no-repeat;
          width: 64px;
          height: 64px;
          z-index: 1;
          right: 40%;
          top: 1rem;
          border-radius: 50%;
        }
      }

      &__Wallet {
        font-size: 12px;
        margin: 0 0;
        width: 35%;
        float: right;

        &__buttons {
          &--button {
            display: none;
            }
    
        }

        &--price {
          height: auto;
          margin: 0 0;
          width: 100%;
          white-space: nowrap;
          color: aqua;
        }
       
      }
    }

  }
  
  @media screen and (max-width: 400px){

    .header-nav {
      &__People {
        &--photo {
          width: 50px;
          height: 50px;
    }
  }

      &__Menu {
        width: 10rem;
      } 

    }
  }