@import "/src/styles/style.scss";

.container {
width: $container;
} 

.description {
    display: flex;
    justify-content: flex-start;
    border-top: 1px solid #999;
    flex-wrap: wrap;

    &__header {
        margin-top: 5rem;
        width: 50%;

    &--text {
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 100%;
        text-transform: uppercase;
        max-width: 60rem;

        &--data {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: #FFFFFF;
            opacity: 0.8;
            border: 1px solid #FFFFFF;
            border-radius: 20px;
            max-width: 35%;
            padding: 1rem 2rem;
            text-align: center;
            margin-top: 3rem;
        }
    }
}
    &__image {
        position: relative;
        margin-top: 5rem;

        & figcaption {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            mix-blend-mode: normal;
            opacity: 1;
            margin-top: 2rem;
            color:#b4b4b4b2;
    }

    &--textBox {
        width: 50rem;
        z-index: 1;
    }


}

.link_coll {
    color: #FFFFFF;
    opacity: 0.8;
    text-decoration: none;
}

.img-fluid {
    max-width: 100%; 
    height: auto; 
    border-radius: 10px;
}
}

figure {
    margin: 0 0 5rem 0;
}

//media//



@media screen and (max-width:820px)  {
    .description {
        width: 100%;
        padding-top: 4rem;
    
        &__header {
            width: 100%;

        &--text {
            font-size: 40px;
            width: 100%;

    
            &--data {
                border-radius: 30px;
                max-width: 60%;
                margin-bottom: 1rem;
                margin-top: 1rem;
                padding: auto;
            }
        }
    }

    &__image {
 

        &--textBox {
            width: 94vw;
        }
    }
}
}






