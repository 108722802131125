@import "../../styles/style.scss";

@mixin layoutBlock {
  display: grid;
  text-align: flex-end;
  font-size: 1.4rem;
  padding-right: 1rem;
  color: #ffffff40;
}

@mixin transition {
  transition: .4s ease-in-out;
}


.registration {
  max-width: $container;

  &--left-decor {
    background-image: url(../../../public/assets/icons/registration/reg_white-semicircle.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

&--photo {
    position: absolute;
    top: 24px;
    left: 28rem;
    z-index: -5;
    width: 1500px;
  }

  &__form {
    margin-top: 55rem;
    font-size: 1.8rem;

    &__layout {
      display: grid;
      grid-template-rows: repeat(4, min);
      grid-template-columns: 105px 136px 451px;
      margin: 4rem 0;

      &__validate {
        grid-column-end: 5;
        grid-row-end: 1;
        padding-left: 2rem;
        padding-top: 2rem;
        align-items: flex-start;
        font-size: 1.4rem;
        padding-right: 1rem;
        color: #ffffff40;

        &--alert {
          padding: 0 0 6rem 0;
          align-content: center;
        }
      }

      &__left {
        font-family: "Prosto One";
        font-size: 2.4rem;
      }

      &__middle {
        // @include layoutBlock;

        &--note {
          margin-top: 1rem;
          border: 1px solid #ffffff40;
          border-radius: 10px;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          max-width: 10rem;
        }
        &--note2 {
          margin-top: 2rem;
          border: 1px solid #ffffff40;
          border-radius: 10px;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          max-width: 10rem;
        }
      
      }

      &__right {
        @include layoutBlock;
        text-align: justify;
        color: #ffffff80;
        position: relative;

        &--checkboxes-list {
          display: grid;
          grid-template-columns: repeat(2, 20rem);

          &__item {
            width: 15rem;
            height: 6rem;
          }
        }

        &__notifications {
          &__radio-btns {
            display: grid;
          }
        }
      }
    }
  }
}


.checkbox {
  display: none;
}


.some {
  height: 50vh;
}

