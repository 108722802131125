@import "/src/styles/style.scss";

.container {
max-width: $container;
} 


.notFoundPage{
    &__Name {
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 100px;
        text-transform: uppercase;
        max-width: 30rem;
        position: relative;
        top: 15rem;
        left: 20rem;
        margin-bottom: 20rem;
    }

    &__text {
        margin-left: 20rem;
        max-width: 50rem;
    }

    &__img {
        background-image: url(../../../public/assets/image/404.png);
        height: 896px;
        width: 798;
        background-repeat: no-repeat;
        position: relative;
        left: 60rem;
        top: -40rem;
    }

    &--link {
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #3AE8E9;
        width: 30rem;
        overflow-wrap: break-word;
    }

    &__edit {
        position: relative;
        max-width: 10rem;
        margin-bottom: 10rem;
        margin-top: -60rem;
        margin-left: 20rem;

        &--button {
            width: 80px;
            height: 80px;
            border: 1px solid white;
            border-radius: 10%;
            opacity: 50%;
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 20rem;
        }

        &--arr {
            background-image: url(../../../public/assets/icons/whitear.png);
            position: absolute;
            top: 3rem;
            left: 20rem;
            width: 54px;
            height: 18px;
            background-repeat: no-repeat;
            opacity: 50%;
            z-index: -1;
        }
    }
}


@media screen and (max-width:414px) {
    .notFoundPage{
        &__Name {
            font-size: 30px;
            line-height: 40px;
            max-width: 30rem;
            top: 5rem;
            left: 0;
            margin-bottom: 35rem;
        }
    
        &__text {
            margin-left: 0;
            max-width: 30rem;
            margin-bottom: 10rem;
            font-size: 15px;
        }
    
        &__img {
            height: 400px;
            width: 390px;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            left: -4rem;
            top: -60rem;
        }
    
        &--link {
            font-family: 'Prosto One';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #3AE8E9;
            width: 30rem;
            overflow-wrap: break-word;
        }
    
        &__edit {
            position: relative;
            max-width: 10rem;
            margin-bottom: 10rem;
            margin-top: -45rem;
            margin-left: 0;
    
            &--button {
                width: 80px;
                height: 80px;
                border: 1px solid white;
                border-radius: 10%;
                opacity: 50%;
                background-color: transparent;
                position: absolute;
                top: 0;
                left: 20rem;
            }
    
            &--arr {
                background-image: url(../../../public/assets/icons/whitear.png);
                position: absolute;
                top: 3rem;
                left: 20rem;
                width: 54px;
                height: 18px;
                background-repeat: no-repeat;
                opacity: 50%;
                z-index: -1;
            }
        }
    }
    
}