@import "../../styles/style.scss";

.form-title {
  margin-top: 50rem;
  display: grid;
  grid-template-rows: repeat(6, min);
  grid-template-columns: 105px 136px 451px;

  &__main, &__slash  {
    font-size: 14px;
    color:  #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Source Sans Pro';
  }

  &__main {
    text-decoration-color: #3AE8E9;
    text-underline-offset: 5px;
  }
  
}

@media screen and (max-width:414px) {
  .form-title {
    margin-top: -20rem;
    margin-left: -2rem;
    grid-template-columns: 4rem 4rem 20rem;
    grid-column-gap: 3rem;
    
    &__main, &__slash  {
      margin-top: 9rem;
    }

    &__main {
      text-decoration-color: #3AE8E9;
      text-underline-offset: 5px;
    }

    &__slash {
      grid-column: 3;
    }
  
    &--reg-of-user {
      font-size: 32px;
      grid-row: 2;
      width: 35rem;
      margin-top: 2rem;
    }
  }
}