@import "../../styles/style.scss";

.container {
  @include container;
}

@font-face {
  font-family: "Prosto One";
  src: url(../../../public/fonts/ProstoOne-Regular.ttf) format("truetype");
  font-weight: normal;
}

.welcome {
  margin-bottom: 10rem;

  &--photo {
    position: absolute;
    object-fit: contain;
    object-position: center;
    top: 24px;
    right: 165px;
    z-index: -2;
    height: 890px;
    max-width: 100%;
    
  }

  &__decor {
    position: absolute;
    top: 300px;
    left: 48px;
    background-image: url(../../../public/assets/image/white-semicircle.png);
    width: 182px;
    height: 182px;
  }

  &__figure {
    position: absolute;
    background-image: url(../../../public/assets/image/figure-circles.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 52px;
    height: 131px;
    right: 50px;
    top: 300px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    justify-content: center;
    align-items: center;

    &__buttons {
      width: 85rem;
    }

    &__description {
      font-size: 2.4rem;
      margin-right: 30rem;
      
      &--item {
        text-align: right;
      }
    }

    &--title {
      padding-top: 10vh;
      font-size: 100px;
      width: 80rem;
      font-family: "Prosto one";
      
    }
    &__buttons {
        display: flex;

      &--button {
        width: 174px;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #fff;
        background-color: rgba(255, 255, 255, 0);
        margin-top: 4rem;
        margin-right: 1.6rem;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 20rem;
        color: white;
        cursor: pointer;
  
        &:hover {
          background-color: rgba(255, 255, 255, 1);
          color: #E9453A;
        }
      }
    }
    
  }

  @media (max-width: 1536px) and (min-width: 1200px){
    .welcome {
      &--photo {
        height: 580px;
      }

      &__wrapper {
        margin-left: 35vw;
        padding-top: 3rem;

        &__description {
          font-size: 1.8rem;
        }

        &--title {
          padding-top: 5vh;
          font-size: 7rem;
          width: 50rem;
        }

        &--button {
          margin-top: 2.4rem;
        }
      }
    }
  }

  &__nav-menu {
    margin-bottom: 20rem;
  }
}

@media screen and (max-width: 414px) {


  .welcome {
    // margin-left: -58.4rem;

    &--photo {
      top: 24px;
      right: 0;
      height: 890px;
      max-width: 100%;
      
    }
  
    &__decor {
      display: none;
    }
  
    &__figure {
      display: none;
    }
  
    &__wrapper {

      &--title {
        padding-top: 10vh;
        font-size: 50px;
        width: auto;
        font-family: "Prosto one";
        align-items: baseline;
        
      }
      &__buttons {
        width: 98vw;
        justify-content: space-around;
        

        &--button {
          margin-top: 15rem;
          margin-bottom: 10rem;
          margin-right: 0;
    
          &:hover {
            background-color: rgba(255, 255, 255, 1);
            color: #E9453A;
          }
      }
      
      }
      &__description {
        margin-right: 0;

        &--item {
          width: 94vw;
      }
    }
    }
}

}


@media screen and (min-width: 415px) and (max-width: 820px)  {
  .welcome {
margin-bottom: 15rem;
    &--photo {
      left: 0;
    }
  
    &__decor {
      top: 30rem;
      left: 1rem;
      width: 120px;
      height: 120px;
      background-size: contain;
    }
  
    &__figure {
      display: none;
    }
  
    &__wrapper {
      &__buttons {
        width: auto;
        &--button {
          margin-bottom: 10rem;
        }
      }
  
      &__description {
        margin-right: 0;
        
        &--item {
          text-align: right;
        }
      }
  
      &--title {
        font-size: 70px;
        width: auto;
        
      }
      &__buttons {
        display: flex;
      }
      
    }
}
}