@import "../../styles/style.scss";

.checkboxes {
    @include layoutBlock;
    text-align: justify;
    color: #ffffff80;
    position: relative;

    &--checkboxes-list {
      display: grid;
      grid-template-columns: repeat(2, 20rem);
     

        &--name {
          position: relative;
          display: inline-block;
          padding-top: 2rem;
          margin-left: 38px;
          width: 34rem;
          @include transition;
         

          &::before {
            position: absolute;
            top: 15px;
            left: -40px;
            content: "";
            display: inline-block;
            width: 32px;
            height: 32px;
            border: 1px solid #ffffff40;
            border-radius: 8px;
            @include transition;
          }

          &::after {
            content: "";
            position: absolute;
            top: 19px;
            left: -36px;
            display: inline-block;
            background-image: url(../../../public/assets/icons/registration/checkbox-active.svg);
            // ../../../public/assets/icons/registration/checkbox-active.svg
            background-position: center;
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            opacity: 0;
            @include transition;
          }
        }
    }}

    input[type="checkbox"]:checked+.registration__form__layout__right--checkboxes-list__item--name::after {
        opacity: 1;
      }
      
      input[type="checkbox"]:hover+.registration__form__layout__right--checkboxes-list__item--name::before {
        border: 1px solid #3AE8E9;
      }
      
      input[type="checkbox"]:checked+.registration__form__layout__right--checkboxes-list__item--name::before {
        border: 1px solid white;
      }
      
      input[type="checkbox"]:checked+.registration__form__layout__right--checkboxes-list__item--name {
        color: white;
        @include transition;
      }


      @media screen and (max-width: 414px) {
        .checkboxes {  
          &--checkboxes-list {
            width: 90vw;
              &--name {
                width: 4rem;
              }
          }}
      }