@import "../../../styles/style.scss";

.button {
    width: 174px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 400;
    text-transform: uppercase;
    color:#fff;
    cursor: pointer;

    &:hover {
        background-color: rgba(255, 255, 255, 1);
        color: #E9453A;
        cursor: pointer;
        }

    &:focus {
            background-color: rgba(255, 255, 255, 1);
            color: #E9453A;
            }
        }


        .reg-submitChange {
            margin-bottom: 5rem;
            background-image: url(../../../../public/assets/icons/bluebutton.png);
            height: 60px;
            width: 450px;
            font-family: 'Prosto One';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            text-align: left;
            padding-left: 2rem;
            grid-row: 1;
            grid-column: 3;
            position: relative;
            cursor: pointer;
          
          
            &--arr {
              background-image: url(../../../../public/assets/icons/arr.png);
              width: 37px;
              height: 18px;
              top: 2rem;
              left: 40rem;
              position: absolute;
            }
            &--arr2 {
              background-image: url(../../../../public/assets/icons/arr.png);
              width: 37px;
              height: 18px;
              top: 13rem;
              left: 40rem;
              position: absolute;
            }
          }


        @media screen and (max-width:414px) {
        
          .reg-submitChange {
            margin-left: -27rem;
            display: flex;
            flex-wrap: wrap;
            width: 38rem;
            vertical-align: middle;
          
            &--arr {
              width: 37px;
              height: 18px;
              top: 5rem;
              left: 20rem;
            }
          }
          
          .reg-button {
            padding: 2rem 2rem;
            cursor: pointer;
          }
          
          }