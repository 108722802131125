@import "../../styles/style.scss";
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 100;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.burger {
  position: relative;
  width: auto;
  

  &__button {
    background-image: url(../../../public/assets/icons/burger.svg);
    background-repeat: no-repeat;
    max-height: 10rem;
    width: 7rem;
    background-color: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    
}
  &__menu {
    position: fixed;
    top: -50%;
    left: 0;
    width: 100%; // Изначальная ширина для мобильных устройств
    height: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    transition: top 0.3s ease;

      &__closeButton {
        cursor: pointer;
        align-self: flex-start;
        margin: 3rem;
        background-image: url('../../../public/assets/icons/crest.png');
        width: 15rem;
        height: 20px;
        padding: 1rem 1rem;
        border: none;
        background-color: transparent;
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        vertical-align: middle;

          &--text {
              position: absolute;
              top: 3rem;
              left: 11rem;
              font-family: 'Prosto One', serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              text-transform: uppercase;
              color: #000000;
              z-index: 0;
              height: 5rem;
          }

          &--eye {
              background-image: url(../../../public/assets/icons/orangeeye.png);
              position: absolute;
              top: 1rem;
              left: 50%;
              height: 56px;
              width: 56px;
              
          }

          &--bord {
            border-top: 1px solid rgba(0, 0, 0, 0.404);
            width: 94%;
          }
      }

      &__body {
        width: 80%;
        display: flex;
        justify-content: flex-start;

          &__list {
            margin-top: 5rem;
              list-style-type: none;
              font-family: 'Prosto One';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              text-transform: uppercase;
              color: #000000;
              padding-right: 20rem;

              & li{
                  padding: 2rem;
                  width: 15rem;
                  
                  & a {
                    text-decoration: none;
                    color: #000000;
                  }
                }
          }
      }
  }

  &__menu.open {
    top: 0;
    z-index: 100;
    color: #000000;
  }

}


// Адаптивность для мобильных устройств
@media (max-width: 800px) {
  .burger {
    &__menu {
        &__closeButton {
          height: 5rem;
          margin: 2rem 2rem 3rem;

            &--text {
                top: 5rem;
                left: 2rem;
            }
            &--eye {
              top: 1.5rem;
              left: 45%;
          }
          &--bord {
            border-top: 1px solid rgba(0, 0, 0, 0.404);
           
          }
        }
  
        &__body {
          width: 100%;
          flex-direction: column;
          margin-top: 2rem;
  
              &__list {
                margin-top: 0;
                font-size: 16px;
                line-height: 18px;
                padding-right: 0;
                width: 80%;
  
                & li{
                    padding: 1rem 0;
                    width: 100%;
                  }
            }
              &__links {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-top: 0;
                font-size: 22px;
                line-height: 27px;
                padding-right: 0;
                width: 80%;
  
                &--li{
                  display: block;
                    padding: 1rem 0;
                    width: 100%;
                    height: 10px;
                  }
            }
        }
    }
  
    &__menu.open {
      height: auto;
    }
  }
}



@media (max-width: 767px) {
  .burger__menu {
    width: 100%;
    max-height: 80%;
  }
}

// Адаптивность для планшетов и десктопов
@media (min-width: 768px) {
  .burger__menu {
    width: 100%; // Замените значение на 100%
    left: 0; // Установите значение в 0
  }
}

@media (min-width: 1024px) {
  .burger__menu {
    width: 100%; // Замените значение на 100%
    left: 0; // Установите значение в 0
  }
}