@import "../../styles/style.scss";


.container {
  @include container;
 }
 
.footer {
  background-color: black;
  width: 100vw;
  position: relative;

  &__bord {
    border-top: 1px solid #818181;
    position: absolute;
    top: 1rem;
    left:0;
    height: 1px;
    width: 100%;
  }

   &__icon {
    background-image: url(../../../public/assets/icons/footer-gradient.png);
    background-position: center;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 50%;
    right: 50%;
    bottom: 3rem;
  }

    &__imgCorner {
      position: absolute;
      background-image: url(../../../public/assets/icons/cornerF.png);
      background-position: center;
      width: 32px;
      height: 32px;
      z-index: 9;
      bottom: 3rem;
      right: 5rem;
    }

  &__cols {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: $container;
    padding: 2.4rem 0;
    font-size: 1.4rem;
    height: 85px;
    color: #999999;

    &__left {
      width: 45%;
      display: flex;
      justify-content: space-between;

      &--year {
      padding-right: 10%;
      }
    }

   &__right {
    width: 55%;
    display: flex;
    justify-content: space-evenly;

      &--eternity {
        width: 25rem;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }

      &--eight {
        color: #FFFFFF;
        font-family: "Prosto One";
        font-size: 2.4rem;
        font-weight: 400;
        text-transform: uppercase;
        font-style: normal;
           
      }
   }

  }
}


@media screen and (max-width: 414px) {  

  .footer {

     &__icon {
      left: auto;
      right: 40%;
      bottom: 5rem;
    }
  
      &__imgCorner {
        bottom: 1rem;
        right: 0rem;
      }
  
    &__cols {
      flex-wrap: wrap;
      padding: 2.4rem 0 0 0;
    font-size: 1.4rem;
    height: 85px;
  
      &__left {
        width: 100%;
        margin-left: 0;
        margin-bottom: 1rem;

        &--info {
          width: 10rem;
        }

        &--year {
          padding-right: 0;
          }
      }
  
     &__right {
      width: 100%;
      margin-left: 0;
      justify-content: space-between;
     
      &--eight {
        margin-right: 7rem;
      }
     }
  
    }
  } 
}

@media screen and (min-width: 415px) and (max-width: 820px) {
  .footer {
    margin-bottom: 5rem;

     &__icon {
      left: 40%;
      right: auto;
      bottom: 3rem;
    }
  
      &__imgCorner {
        bottom: -2rem;
        right: 0rem;
      }
  
    &__cols {
      flex-wrap: wrap;

  
      &__left {
        width: 100%;
        margin-left: 0;
        margin-bottom: 1rem;

        &--info {
          width: 10rem;
        }

        &--year {
          padding-right: 0;
          }
      }
  
     &__right {
      width: 100%;
      margin-left: 0;
      justify-content: space-between;
     
      &--eight {
        margin-right: 7rem;
      }
     }
  
    }
  } 
}