@import "../../styles/style.scss";

.block__mini {
    display: flex;
    flex-direction: row;

    &__nameBlock {
        font-family: 'Prosto One', serif;
        font-style: normal;
        font-weight: 400;
        color: #FFFFFF;
        font-size: 12px;
        line-height: 15px;
        margin-top: 3rem;
        margin-right: 15rem;
        position: relative;

        &--new {
            font-size: 24px;
            line-height: 30px;
            text-transform: uppercase;
            margin-bottom: 5rem;
        }

        &--little {
            width: 10rem;
        }

        &__press {
            &--button {
                width: 80px;
                height: 80px;
                border: 1px solid white;
                border-radius: 10%;
                opacity: 50%;
                background-color: transparent;
                position: absolute;
                top: 7rem;
                left: 20rem;
            }

            &--arr {
                background-image: url(../../../public/assets/icons/whitear.png);
                position: absolute;
                top: 10rem;
                left: 20rem;
                width: 54px;
                height: 18px;
                background-repeat: no-repeat;
                opacity: 50%;
                z-index: -1;
            }
        }
       
    }

}

@media (max-width:600px){
    .block__mini {
        flex-direction: column;
        padding: 0 auto;
        margin: 0 auto;
        &__nameBlock {
            margin-bottom: 5rem;
        }
}
    .block__square {
width: 100%;
}
    
} 