$offcanvas-vertical-height: 50vh;
$container: 94vw;
$margin: 0 auto;
$color: #ccc;

@mixin container {
  width: $container;
  margin: $margin;
}

@mixin layoutBlock {
    display: grid;
    text-align: flex-end;
    font-size: 1.4rem;
    padding-right: 1rem;
    color: #999999;
  }
  
  @mixin transition {
    transition: .4s ease-in-out;
  }

  .container {
    @include container;
  }