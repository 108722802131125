@import "../../styles/style.scss";


.aboutUs {
    display: flex;
    justify-content: center;
    margin-top: 8rem !important;
    vertical-align: top;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0 auto;
    width: 82vw;

    &__Left {
        color: gray;
    }

    &__Center {
        max-width: 45rem;
        padding-right: 10rem;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        text-align: right;
        margin: 0 0 0 10rem;
        
    }
    &__Rigth {
        width: 50rem;
        margin: 0 auto;
        position: relative;

        &--arrow {
            position: absolute;
            top: 0;
            left: -10rem;
            background-image: url(../../../public/assets/icons/star.png);
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            }

        &--triangle {
            float: right;
            top: 0;
            left: 60rem;
            background-image: url(../../../public/assets/icons/Path.png);
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
        }
        &--text {
            max-width: 40rem;
            
        }

        &--span {
            color: gray;
            margin-top: 5rem;
          }

        &__buttons {
            display: flex;
            justify-content: space-evenly;
            margin-top: 5rem;

            &__buttonBase {
                width: 40%;
                height: 70px;
                border-radius: 40px;
                border: 1px solid #fff;
                background-color: rgba(255, 255, 255, 0);
                margin-top: 4rem;
                margin-right: 1.6rem;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 15px;
          
                &:hover {
                  background-color: rgba(255, 255, 255, 1);
                  color: #E9453A;
                }

                &:focus {
                    background-color: rgba(255, 255, 255, 1);
                    color: #E9453A;
                    }
                    }
                
            &__buttonBots {
                width: 50%;
                height: 70px;
                border-radius: 40px;
                border: 1px solid #fff;
                background-color: rgba(255, 255, 255, 0);
                margin-top: 4rem;
                margin-right: 1.6rem;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 15px;
        
                &:hover {
                background-color: rgba(255, 255, 255, 1);
                color: #E9453A;
                }

                &:focus {
                    background-color: rgba(255, 255, 255, 1);
                    color: #E9453A;
                    }
                    }
                }
            }
        }

@media screen and (max-width:800px) {
    .aboutUs {
        margin: 5rem auto 0 auto;
        
        &__Left {
            margin: 0 auto;
        }

        &__Center {
            margin: 0 0;         
            font-size: 18px;           
            line-height: 23px;
            padding-right: 0;
            width: 25rem;
            text-align: left;
        }
                        
        &__Rigth {     
            margin-top: 5rem;
            position: relative;
            justify-content: center;
            width: 98vw;
                    
            &--arrow {
                left: 0;
            }
                    
            &--triangle {
                left: 31rem;
                top: 1rem;
            }

            &--text {
                max-width: 80%;
                margin: 0 auto;  
                }
                    
                            &__buttons {
                                display: flex;
                                justify-content: space-evenly;
                                margin-top: 5rem;
                    
                                &__buttonBase {
                                    width: 40%;
                                    height: 70px;
                                    border-radius: 40px;
                                    border: 1px solid #fff;
                                    background-color: rgba(255, 255, 255, 0);
                                    margin-top: 4rem;
                                    margin-right: 1.6rem;
                                    font-weight: 400;
                                    text-transform: uppercase;
                                    font-size: 15px;
                              
                                    &:hover {
                                      background-color: rgba(255, 255, 255, 1);
                                      color: #E9453A;
                                    }
                    
                                    &:focus {
                                        background-color: rgba(255, 255, 255, 1);
                                        color: #E9453A;
                                        }
                                        }
                                    
                                &__buttonBots {
                                    width: 50%;
                                    height: 70px;
                                    border-radius: 40px;
                                    border: 1px solid #fff;
                                    background-color: rgba(255, 255, 255, 0);
                                    margin-top: 4rem;
                                    margin-right: 1.6rem;
                                    font-weight: 400;
                                    text-transform: uppercase;
                                    font-size: 15px;
                            
                                    &:hover {
                                    background-color: rgba(255, 255, 255, 1);
                                    color: #E9453A;
                                    }
                    
                                    &:focus {
                                        background-color: rgba(255, 255, 255, 1);
                                        color: #E9453A;
                                        }
                                        }
                                    }
                                }
                            }
}
