@import "/src/styles/style.scss";
    
    .nav-menu {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid white;
    
        &--item {
            text-decoration: none;
            padding: 3rem 5rem;
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
            color:white;
            border-bottom: 4px solid transparent;
            width: auto;
          
    
            &:hover {
                border-bottom: 4px solid #FF8E00;
                color:white;
                display: inline-block;
                z-index: 70;
            }

            &:active {
                border-bottom: 4px solid #FF8E00;
                color:white;
                display: inline-block;
                z-index: 70;
            }
    
        }
    }

    @media screen and (max-width:820px) {
        .nav-menu {
            display: none;;
        }
    }