@import "../../styles/style.scss";

.cardsOfMissions {
    display: flex;
    flex-direction: row;

    &__card {
    padding-top: 5rem;
    display: inline;
    padding: 2rem 1rem 0;         
    border: 1px solid rgba(255, 255, 255, 0.400);
    border-radius: 3%;
    cursor: pointer;
    margin: 1rem;

    &:hover {
        border: 1px solid #3AE8E9;
    }
    

    &--img {
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;        
        width: 40rem;
        height: 20rem;
        margin-left: 1.5rem;
    }

    &--text {
        padding: 0 1.5rem;
        margin: 0 0 2rem;
        width: 40rem;
        text-align: right;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        text-transform: uppercase;
        word-wrap: break-word;
    }

    &__group {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 2rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        margin: 0 1.5rem;
        

        &__element1left {

            &--head {
                font-family: 'Source Sans Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #3AE8E9;

            }
            &--data {
                font-family: 'Prosto One';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #999999;
                margin-top: 1rem;
                width: 40px;
            }
        }
        
        &__element2rigth {
            &--stars {
                display: flex;
                background-color: black;
                
            }

            &--stars p {
                background-image: url(../../../public/assets/icons/maskstar.svg);
                width: 16px;
                height: 16px;
            }

            &--arrow {
                width: 54px;
                height: 18px;
                background-image: url(../../../public/assets/icons/arrBlue.svg);
                background-repeat: no-repeat;
                top: 6rem;
                right: 1.5rem;
                position: absolute;
                cursor: pointer;
            }
        }

    }
}
}

@media screen and (max-width:800px) {
    .cardsOfMissions {
        width: 100%;

        .cardsOfMissions__card {
            width: 96%;
            margin-left: 1rem;
            padding: 0 0;

        &--img {      
            width: 100%;
            border-radius: 3% 3% 0 0;
            margin-left: 0;
        }

        &--text {
            width: 96%;
            padding: 0 0;
            text-align: right;
            font-size: 14px;
            line-height: 18px;
            text-align: right;
            text-transform: uppercase;
        }
}}
}

