@import "../../styles/style.scss";

@mixin layoutBlock {
  display: grid;
  text-align: flex-end;
  font-size: 1.4rem;
  padding-right: 1rem;
  color: #ffffff40;
}

@mixin transition {
  transition: .4s ease-in-out;
}


.registration {


  &--left-decor {
    background-image: url(../../../public/assets/icons/registration/reg_white-semicircle.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

&--photo {
    position: absolute;
    top: 24px;
    left: 28rem;
    z-index: -5;
    width: 1500px;
  }

  &--photoMobile {
    display: none;
  }

  &__form {
    margin-top: 55rem;
    font-size: 1.8rem;

    &--head {
      margin-top: 30rem;
      font-family: 'Prosto One';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      margin-left: 2rem;
    }

    &__layout {
      display: grid;
      grid-template-rows: repeat(4, min);
      grid-template-columns: 105px 136px 451px;
      margin: 4rem 0;

      &__validate {
        grid-column-end: 6;
        grid-row-end: 1;
        padding-left: 2rem;
        padding-top: 2rem;
        align-items: flex-start;
        font-size: 1.4rem;
        padding-right: 1rem;
        color: #ffffff40;
        margin-left: 5rem;

        &--alert {
          padding: 0 0 6rem 0;
          align-content: center;
          color: orange;
        }
      }

      &__left {
        font-family: "Prosto One";
        font-size: 2.4rem;
      }

      &__middle {
        @include layoutBlock;
        color: #999999;

        &--note, &--note2 {
          margin-top: 1rem;
          border: 1px solid #ffffff40;
          border-radius: 10px;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          max-width: 10rem;
          vertical-align: middle;
          line-height: 20px;
          padding: 0 5px;
        }
      
      }

      &__right {
        @include layoutBlock;
        text-align: justify;
        color: #ffffff80;
        position: relative;

        &--checkboxes-list {
          display: grid;
          grid-column: 3;

          &__item {
            width: 15rem;
            height: 6rem;

            &--name {
              position: relative;
              display: inline-block;
              padding-top: 2rem;
              margin-left: 38px;
              width: 34rem;
              grid-column: 3;
              @include transition;

              &::before {
                position: absolute;
                top: 15px;
                left: -40px;
                content: "";
                display: inline-block;
                width: 32px;
                height: 32px;
                border: 1px solid #ffffff40;
                border-radius: 8px;
                @include transition;
              }

              &::after {
                content: "";
                position: absolute;
                top: 19px;
                left: -36px;
                display: inline-block;
                background-image: url(../../../public/assets/icons/registration/checkbox-active.svg);
                background-position: center;
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
                opacity: 0;
                @include transition;
              }
            }
          }
        }

        &__notifications {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #999999; 

          &__radio-btns {
            display: grid;
          }
        }
      }
    }
  }
}

.bord {
  border-top: 1px solid #ffffff40;
  position: relative;
  width: 69rem;
  height: 1px;
}

.reg-text-area {
  width: 45rem;
  height: 16rem;
  background-color: black;
  margin-top: 2.4rem;
  padding-left: 2.4rem;
  color: white;
}

.registration-inputReg {
  background-color: #ffffff00;
  border: 1px solid #ffffff40;
  border-radius: 8px;
  margin-bottom: 2rem;
  height: 6rem;
  color: #FFF;
  font-size: 1.8rem;
  padding: 0 2.4rem;
  margin-left: 2.5rem;
  grid-column: 3;
  width: 450px;
}


.checkbox {
  display: none;
}

.checkbox:checked+.registration__form__layout__right--checkboxes-list__item--name::after {
  opacity: 1;
}

.checkbox:hover+.registration__form__layout__right--checkboxes-list__item--name::before {
  border: 1px solid #3AE8E9;
}

.checkbox:checked+.registration__form__layout__right--checkboxes-list__item--name::before {
  border: 1px solid white;
}

.checkbox:checked+.registration__form__layout__right--checkboxes-list__item--name {
  color: white;
  @include transition;
}

.reg-submit {
  margin-bottom: 20rem;
  background-image: url(../../../public/assets/icons/bluebutton.png);
  height: 60px;
  width: 450px;
  font-family: 'Prosto One';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: left;
  padding-left: 2rem;
  border: none;
  background-color: transparent;
  border-radius: 10px;

  &--arr {
    position: absolute;
    background-image: url(../../../public/assets/icons/arr.png);
    width: 37px;
    height: 18px;
    top: 2rem;
    left: 38rem;
  }
 
}

.some {
  height: 50vh;
}

@media screen and (max-width:414px) {

  label {
    width: 10rem;
  }
  .registration {
    max-width: 98vw;

    &--photo {
      display: none;
    }

    &--photoMobile {
      position: absolute;
      width: 240px;
      height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      left: 146px;
      top: 30px;
      z-index: -1;
      display: block;
    }

    &__form {

      &--head {
        margin-top: 30rem;
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        margin-left: 2rem;
      }
  
      &__layout {
        grid-template-rows: repeat(9, min);
        grid-template-columns: 4rem 4rem 20rem;
       width: 90vw;

        &__validate {
          position: absolute;
          top: 65rem;
          
        &--alert {
          grid-column: 2;
          grid-row: 1;
          width: 25rem;
          z-index: -1;
          margin-top: 2rem;
          margin-left: 5rem;
        }
        }

        &__left {
          height: 5rem;
          grid-row: 1;
          grid-column: 1;
        }
        

        &__right {
          grid-row: 2;
          grid-column: 1;
          z-index:  1;

          &--checkboxes-list {
           width: 90vw;
           grid-column: 1;
            
            &__item {
              margin-bottom: 1rem;
              & > label {
                margin-top: 1rem;
              }
              &--name {
                width: 4rem;
                
              }
            }
          }
         
          &__notifications {
            margin-left: 0;
            grid-row: 2 / 2;
            grid-column: 1 / 4;
            width: 90vw;

            &__radio-btns {
              display: grid;
              margin-top: 1rem;

              & > label {
                margin-top: 1rem;
                }

            }
          }
        }
      }
    }
  }
  
.nameBottom {
  width: 80vw;
}
.text2 {
width: 30rem;
}
  .reg-text-area {
    width: 84vw;
    grid-column: 1;
    margin-left: 0;
  }

  .reg-button {
  margin-top: 2rem;
  }


  .registration-inputReg {
    margin: 1rem auto;
    grid-column: 1;
    width: 81vw;
  }
  
  
  .checkbox {
    display: none;
  }
  
  .reg-submit {
    margin-bottom: 5rem;
    width: 94vw;
   
    &--arr {
      width: 37px;
      height: 18px;
      top: 4rem;
      left: 31rem;
    }
  }
  
  .bord {
    width: 94vw;
   
  }
}


@media screen and (min-width:415px) and (max-width: 820px) {
  .registration {
    max-width: 98vw;

    &--photo {
      display: none;
    }

    &--photoMobile {
      position: absolute;
      width: 240px;
      height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      left: 146px;
      top: 30px;
      z-index: -1;
      display: block;
    }

    &__form {
      &--head {
        margin-top: 30rem;
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        margin-left: 2rem;
  
      }
  
      &__layout {
        grid-template-rows: repeat(9, min);
        grid-template-columns: 4rem 4rem 20rem;
        width: 50vw;

        &__validate {
          position: absolute;
          top: 79rem;
          
        &--alert {
          grid-column: 2;
          grid-row: 1;
          width: 25rem;
          z-index: -1;
          margin-top: 2rem;
          margin-left: 5rem;
        }
        }

        &__left {
          height: 5rem;
          grid-row: 1;
          grid-column: 1;
        }
        

        &__right {
          grid-row: 2;
          grid-column: 1;
          z-index:  1;

          &--checkboxes-list {
           width: 90vw;
            
            &__item {
              margin-bottom: 1rem;
              & > label {
                margin-top: 1rem;
              }
              &--name {
                width: 10rem;
                
              }
            }
          }
         
          &__notifications {
            margin-left: 0;
            grid-row: 2 / 2;
            grid-column: 1 / 4;
            width: 90vw;

            &__radio-btns {
              display: grid;
              margin-top: 1rem;

              & > label {
                margin-top: 1rem;
                }

            }
          }
        }
      }
    }
  }
  
.nameBottom {
  width: 50vw;
}
.text2 {
width: 30rem;
}
  .reg-text-area {
    width: 50vw;
    grid-column: 1;
    margin-left: 0;
  }

  .reg-button {
  margin-top: 2rem;
  }


  .registration-inputReg {
    margin-left: 0;
    grid-column: 1;
    width: 50vw;
  }
  
  
  .checkbox {
    display: none;
  }
  
  .reg-submit {
    margin-bottom: 5rem;
    width: 38rem;
   
    &--arr {
      width: 37px;
      height: 18px;
      top: 4rem;
      left: 31rem;
    }
  }
  
  .bord {
    width: 38rem;
   
  }
}
