@import "../../styles/style.scss";
@import "../../components/CheckboxGroup/checkboxGroup.scss";

.avatar {
  width: 64px;
  height: 64px;
  z-index: 6;
  right: 48%;
  top: 2rem;
  border-radius: 50%;
  background-size: cover;
  grid-column: 4;
  margin-left: 25rem;
}

.registration {
  max-width: $container;

  &--left-decor {
    background-image: url(../../../public/assets/icons/registration/reg_white-semicircle.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

&--photo {
    position: absolute;
    top: 24px;
    left: 28rem;
    z-index: -5;
    width: 1500px;
  }

  &__form {
    margin-top: 55rem;
    font-size: 1.8rem;

    


    &__layout {
      display: grid;
      grid-template-rows: repeat(4, min);
      grid-template-columns: 105px 136px 451px;
      margin: 4rem 0;

      &__validate {
        grid-column-end: 5;
        grid-row-end: 1;
        padding-left: 2rem;
        padding-top: 2rem;
        align-items: flex-start;
        font-size: 1.4rem;
        padding-right: 1rem;
        color: #ffffff40;

        &--alert {
          padding: 0 0 6rem 0;
          align-content: center;
        }
      }

      &__left {
        font-family: "Prosto One";
        font-size: 2.4rem;
      }

      &__middle {
        @include layoutBlock;

        &--note {
          margin-top: 1rem;
          border: 1px solid #ffffff40;
          border-radius: 10px;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          max-width: 10rem;
        }
        &--note2 {
          margin-top: 2rem;
          border: 1px solid #ffffff40;
          border-radius: 10px;
          height: 2rem;
          text-align: center;
          font-size: 1rem;
          max-width: 10rem;
        }
      
      }

      &__right {
        @include layoutBlock;
        text-align: justify;
        color: #ffffff80;
        position: relative;

        &--photo {
          display: grid;
          grid-template-rows: 3, min;
          grid-template-columns: 105px 136px 105px;
          grid-column:1;
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #999999;
        }
    
        &--photo h1 {
          grid-column:2;
          font-size: 14px;
          margin-right: 5rem;
        }

        &__buttons{
          grid-column: 3;
          position: relative;
        }
        

        // &--checkboxes-list {
        //   display: grid;
        //   grid-template-columns: repeat(2, 20rem);

        //   &__item {
        //     width: 15rem;
        //     height: 6rem;
        //   }
        // }

        &__notifications {
          &__radio-btns {
            display: grid;
            margin-top: 1rem;

            & > label {
              margin-top: 1rem;
              
              }
          }
        }
      }
    }
  }
}


.registration-inputChange {
  background-color: #ffffff00;
  border: 1px solid #ffffff40;
  border-radius: 8px;
  margin-bottom: 2rem;
  height: 6rem;
  color: #FFF;
  font-size: 1.8rem;
  padding: 0 2.4rem;
  grid-column: 3;
  width: 450px;
  margin-left: 4rem;
}



.checkbox {
  display: none;
}


.some {
  height: 50vh;
}


//media//

@media screen and (max-width:414px) {
  .registration {
    max-width: 98vw;

   
}

.registration-inputChange {
  width: 37rem;
  grid-column: 1;
  margin-left: 0;
}

}