@import "../../styles/style.scss";

.container {
 @include container;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  background-color: black;
  bottom: 0px;
  width: 100vw;
  position: relative;

    &__imgEye {
      position: absolute;
      background-image: url(../../../public/assets/icons/symbol.png);
      background-position: center;
      width: 80px;
      height: 80px;
      top: 38rem;
      right: 23rem;
      z-index: 15;
    }

    &__imgOval {
      position: absolute;
      background-image: url(../../../public/assets/icons/OvalForEye.png);
      background-position: center;
      width: 138px;
      height: 138px;
      z-index: 9;
      top: 38rem;
      right: 20rem;
    }

    &__imgFigure {
      position: absolute;
      background-image: url(../../../public/assets/icons/Group.png);
      background-position: center;
      width: 92px;
      height: 272px;
      z-index: 9;
      top: 39rem;
      left: 0;
    }

    &__br {
      border-bottom: 1px solid #999;
      position: absolute;
      bottom: 36rem;
      width: $container;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
     
    }

    &__nav{
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-family: 'Prosto One';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      padding: 2rem 0;
    
      margin-top: 30rem;
      position: relative;

        &__list{
          list-style-type: none;
          padding-right: 20rem;
          padding-left: 7rem;
          position: relative;

        &--li{
          padding: 2rem

        }
    } 
  }
}

.span{
        color: #999999;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 10rem;
}

@media screen and (max-width:414px) {
  .footer-nav {
    display: none;
}
}

@media screen and (min-width:415px) and (max-width:820px) {
  .footer-nav {
    display: none;
    // width: 94%;
  
    //   &__imgEye {
    //     top: 13rem;
    //     right: 8rem;
    //   }
  
    //   &__imgOval {
    //     top: 10rem;
    //     right: 5rem;
    //   }
  
    //   &__imgFigure {
    //     top: 14rem;
    //   }
  
  
    //   &__br {
    //     border-bottom: 1px solid #999;
    //     position: absolute;
    //     bottom: 36rem;
    //     width: 100%;
    //     top:35%;
       
    //   }
  
    //   &__nav{
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     font-family: 'Prosto One';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 12px;
    //     line-height: 15px;
    //     text-transform: uppercase;
    //     padding: 2rem 0;
      
    //     margin-top: 10rem;
    //     position: relative;
  
    //       &__list{
    //         list-style-type: none;
    //         padding-right: 0;
    //         padding-left: 7rem;
    //         position: relative;
  
    //       &--li{
    //         padding: 2rem
  
    //       }
    //   } 
    // }
  }
  
  .span{
          margin-bottom: 0;
  }
}