* p {
  margin: 0;
  bottom: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}

::-webkit-scrollbar { /* chrome based */
  width: 0px;  /* ширина scrollbar'a */
  background: transparent;  /* опционально */
}

#root, html, body{
  margin: 0 0;
  padding: 0 0;
  width: 100%;
  height: 100%;
}
