.image-uploader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  
    input[type='file'] {
      cursor: pointer;
    }
  
    button {
      cursor: pointer;
      padding: 10px 20px;
      border: none;
      overflow-wrap: normal;
      border-radius: 5px;
      width: 15rem;
      
  
      &:disabled {
        background-color:#89f1f2;
      }
    }
  }

  .fileInput {
    display: none;
  }
  
  .customFileButton {
    background-color:#89f1f2;
  }

  .customLoadButton {
    background-color: #248a8a;
    color: white;
  }

  .photoCheck {
    order: 1;
  }

  