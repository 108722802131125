.commentSection {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 0;
    color:white;
  }
  
  h3 {
    font-family: 'Prosto One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    width: 50%;
  }

  h6 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
    width: 45%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    position: relative;
  
    input,
    textarea {
      padding: 0.5rem;
    }
  
    textarea {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 10px;
      height: 24rem;
      background-color: #000000;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      opacity: 0.6;
      padding: 16px 24px;
      color:white;
      resize: none;
    }
  }
  
  .buttonSend {
    align-self: flex-end;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: linear-gradient(to right, #4c33d1 , #bd33fa);
    height: 60px;
    width: 276px;
    border: none;
    border-radius: 10px;
    color: white;
    text-align: left;
    background-size: cover;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  .buttonClip {
    background-color: transparent;
    background-image: url('../../../public/assets/icons/clip.png');
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    position: absolute;
    bottom: 2rem;
    right: 31rem;
    cursor: pointer;
}

  .comments {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 4px solid #3AE8E9;
    margin-top: 5rem;
    padding-top: 5rem;
  }
  
  .comment {
    background-color: #000000;
    padding: 1rem;
    border-radius: 0.5rem;
 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  
    .userInfo {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 1rem;
      
    }

    .emailNameBlock{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 1rem;


      .email {
        font-weight: bold;
        max-width: 50vw;
        margin-right: 3rem; 
        margin-bottom: 1rem;
        font-family: 'Prosto One';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      .userName {
        max-width: 50vw;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #999999;
        margin-right: 3rem;
      }
    }
  
    
  
    .timestamp {
      font-size: 0.8rem;
      color: hsla(0, 0%, 100%, 0.4);
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      border: 1px solid hsla(0, 0%, 100%, 0.4);
      border-radius: 15px;
      padding: 5px;
    }
  
    .text {
      margin-bottom: 1rem;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  
    .reactions {
     width: 30rem;
     height: 4rem;
     position: relative;
     display: flex;
     justify-content: space-between;
     flex-wrap: nowrap;
     align-items: center;
  
      button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }
  
      .wavyArr {
        background-image: url(../../../public/assets/icons/wavyArr.png);
        filter: invert(1);
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .alert {
        background-image: url(../../../public/assets/icons/alert.png);
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        padding: 1.5rem;
        background-position: center;
      }

      .likes {
        display: flex;
        justify-content: space-between;
        width: 3rem;
      }

      .dislikes {
        display: flex;
        justify-content: space-between;
        width: 3rem;
      }

      .falseLike {
        background-image: url(../../../public/assets/icons/fingerUp.png);
        filter: invert(1);
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        opacity: 0.4;
      }

      .trueLike {
        background-image: url(../../../public/assets/icons/fingerUp.png);
        filter: invert(1);
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
      }


      .falseDis {
        background-image: url(../../../public/assets/icons/fingerDown.png);
        filter: invert(1);
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        opacity: 0.4;
      }

      .trueDis {
        background-image: url(../../../public/assets/icons/fingerDown.png);
        filter: invert(1);
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
      }

      .likeCount {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        height: 10px;
      }

      .dislikeCount {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        height: 10px;
      }
  
      .dislikeButton {
        color: #ff7a7a;
      }
    }
  }

  @media screen and (max-width:414px) {
    .buttonSend {
      height: 5rem;
      width: 70vw;
    }
  
    .buttonClip {
      width: 13vw;
      height: 5rem;
      right: 82%;
  }

  h6 {
    width: 90%;
  }
  }