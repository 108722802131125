@import "../../styles/style.scss";



.auth-menu {
  position: absolute;
  top: 3.5rem;
  left: 300px;

  &--item {
    margin-right: 3rem;
    color: inherit;
    text-decoration: none;
    font-size: 1.4rem;
    height: 5.4rem;
    transition: .3s;
    padding-bottom: 3rem;
    border-bottom: 4px solid transparent;


    &:hover {
      color: inherit;
      border-bottom: 4px solid #3AE8E9;

    }
  }
}

@media screen and (max-width:414px) {
  .auth-menu {
    display: none;
  }

  .eye {
    background-image: url(../../../public/assets/icons/OvalForEye.png);
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 5rem;
    left: 5rem;
  }

  .blackCircle {
    background-image: url(../../../public/assets/icons/logo-symbol.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 5rem;
    left: 5rem;
  }

}

.auth-menu {
  position: absolute;
  top: 3.5rem;
  left: 300px;

  &--item {
    margin-right: 3rem;
    color: inherit;
    text-decoration: none;
    font-size: 1.4rem;
    height: 5.4rem;
    transition: .3s;


    &:hover {
      color: inherit;
      border-bottom: 4px solid #3AE8E9;

    }
  }
}

@media screen and (min-width:414px) and (max-width: 820px) {
  .auth-menu {
    position: absolute;
    top: 3.5rem;
    left: 300px;
    width: auto;
  
    &--item {
      margin-right: 3rem;
      color: inherit;
      text-decoration: none;
      font-size: 1.4rem;
      height: 5.4rem;
      transition: .3s;
  
  
      &:hover {
        color: inherit;
        border-bottom: 4px solid #3AE8E9;
  
      }
    }
  }

}