@import "/src/styles/style.scss";

.wallet {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 10rem auto;
    flex-wrap: wrap;
   
    &__triangle {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../../../public/assets/image/white-semicircle.png);
        width: 182px;
        height: 182px;
    }
    
    &__figure {
        position: absolute;
        background-image: url(../../../public/assets/image/figure-circles.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 52px;
        height: 131px;
        right: 0;
        top: 0;
    }

    &__about {
        width: 75rem;
        margin-left: 10rem;

        &__text{
            display: flex;
            justify-self: flex-start;
            align-items: baseline;
            margin-bottom: 3%;
            margin-left: 15%;
            margin-right: auto;

            &--date {
                font-family: 'Source Sans Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 13px;
                mix-blend-mode: normal;
                opacity: 0.6;
                border: 1px solid white;
                border-radius: 10px;
                max-width: 17rem;
                width: 15rem;
                text-align: center;
                margin-right: 3%;
            }

            &--link {
                font-family: 'Prosto One';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #3AE8E9;
                width: 30rem;
                overflow-wrap: break-word;
            }

        }
        

        &__name {
            font-family: 'Prosto One';
            font-style: normal;
            font-weight: 400;
            font-size: 60px;
            line-height: 100px;
            text-transform: uppercase;
            width: 100%;
            overflow-wrap: break-word;

        }

        &__edit {
            position: relative;
            max-width: 10rem;
            left: 15%;
            top: 5rem;
            margin-bottom: 10rem;

            &--button {
                width: 80px;
                height: 80px;
                border: 1px solid white;
                border-radius: 10%;
                opacity: 50%;
                background-color: transparent;
                position: absolute;
                top: 0;
                left: 200%;
            }

            &--arr {
                background-image: url(../../../public/assets/icons/whitear.png);
                position: absolute;
                top: 3rem;
                left: 200%;
                width: 54px;
                height: 18px;
                background-repeat: no-repeat;
                opacity: 50%;
                z-index: -1;
            }
        }

    }

    &__purse {
        border: 1px solid rgba(255, 255, 255, 0.39);
        border-radius: 3%;
        width: 60rem;
        padding: 3rem;
        position: relative;
        margin-right: 8rem;
        height: 32rem;

        &__info {
            vertical-align: center;
            margin-bottom: 2rem;
            

            &--button {
                border: 1px solid rgba(255, 255, 255, 0.39);
                border-radius: 40px;
                background-color: transparent;
                font-family: 'Prosto One';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 15px;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                padding:2rem 3rem;
                margin: 2rem 0;
            }

            &--picEye {
                position: absolute;
                background-image: url(../../../public/assets/icons/logo-eye.png);
                width: 32px;
                height: 32px;
                background-repeat: no-repeat;
                right: 10.6rem;
                top: 6.6rem;
                z-index: 7;
        
            }

            &--picWit {
                position: absolute;
                background-image: url(../../../public/assets/icons/Oval1.png);
                width: 44px;
                height: 44px;
                background-repeat: no-repeat;
                right: 10rem;
                top: 6rem;
                z-index: 6;
            }

            &--picGrey {
                position: absolute;
                background-image: url(../../../public/assets/icons/Oval2.png);
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                z-index: 1;
                right: 7rem;
                top: 6rem;
            }

            &--picDark {
                position: absolute;
                background-image: url(../../../public/assets/icons/Oval3.png);
                width: 42px;
                height: 42px;
                background-repeat: no-repeat;
                z-index: 4;
                right: 8.5rem;
                top: 6rem;
                
            }
        }

        &__add {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            color: #999999;
            margin: 3rem 3rem 3rem 0;

            &__balance {

                &--text {
                    margin-bottom: 3rem;
                }

                &--money {
                    font-family: 'Prosto One';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    color: #FFFFFF;
                    border-bottom: 1px solid #3AE8E9;
                }

            }

            &__currency {

                &--curse {
                    margin-bottom: 3rem;
                }

                &--ytj {
                    font-family: 'Prosto One';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    color: #FFFFFF;
                    border-bottom: 1px solid #3AE8E9;
                }
            }
        }
    }
}

@media screen and (max-width:1730px){
    .wallet {
        &__about {
            width: 70rem;
            }
        }
    }
    
@media screen and (max-width:1680px) {
    .wallet {
        &__about {
            width: 60rem;
            }
        &__purse {
            width: 60rem;
        }
    }
}
@media screen and (max-width:820px) {
    .wallet {
        margin-top: 5rem;
        margin-right: 5rem;
        margin-bottom: 5rem;
    
        &__triangle {
            display: none;
        }
        
        &__figure {
            display: none;
        }
    
        &__about {
            &__name {
                font-size: 40px;
                line-height: 40px;
                width: 60%;
            }
    
            &__edit {
                left: 0;
                &--button {
                    width: 60px;
                    height: 60px;
                }

                &--arr {
                    top: 2rem;
                    width: 27px;
                    height: 18px;
                    background-position-x: -27px;
                }
            }
    
        }
    
        &__purse {
            border: 1px solid rgba(255, 255, 255, 0.39);
            border-radius: 3%;
            width: 94vw;
            padding: 3rem;
            position: relative;
            margin-right: 0;
            margin-left: 4rem;
            height: 32rem;
    
            &__info {
                vertical-align: center;
                margin-bottom: 2rem;
                
    
                &--button {
                    border: 1px solid rgba(255, 255, 255, 0.39);
                    border-radius: 40px;
                    background-color: transparent;
                    font-family: 'Prosto One';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 15px;
                    text-align: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding:2rem 3rem;
                    margin: 2rem 0;
                }
    
                &--picEye {
                    position: absolute;
                    background-image: url(../../../public/assets/icons/logo-eye.png);
                    width: 32px;
                    height: 32px;
                    background-repeat: no-repeat;
                    right: 10.6rem;
                    top: 6.6rem;
                    z-index: 7;
            
                }
    
                &--picWit {
                    position: absolute;
                    background-image: url(../../../public/assets/icons/Oval1.png);
                    width: 44px;
                    height: 44px;
                    background-repeat: no-repeat;
                    right: 10rem;
                    top: 6rem;
                    z-index: 6;
                }
    
                &--picGrey {
                    position: absolute;
                    background-image: url(../../../public/assets/icons/Oval2.png);
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    z-index: 1;
                    right: 7rem;
                    top: 6rem;
                }
    
                &--picDark {
                    position: absolute;
                    background-image: url(../../../public/assets/icons/Oval3.png);
                    width: 42px;
                    height: 42px;
                    background-repeat: no-repeat;
                    z-index: 4;
                    right: 8.5rem;
                    top: 6rem;
                    
                }
            }
    
            &__add {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'Source Sans Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 15px;
                color: #999999;
                margin: 3rem 3rem 3rem 0;
    
                &__balance {
    
                    &--text {
                        margin-bottom: 3rem;
                    }
    
                    &--money {
                        font-family: 'Prosto One';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        border-bottom: 1px solid #3AE8E9;
                    }
    
                }
    
                &__currency {
    
                    &--curse {
                        margin-bottom: 3rem;
                    }
    
                    &--ytj {
                        font-family: 'Prosto One';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 40px;
                        color: #FFFFFF;
                        border-bottom: 1px solid #3AE8E9;
                    }
                }
            }
        }
    }}


@media screen and (max-width:414px) {
    .wallet {
    width: 100vw;
    margin: 0 auto;

        &__triangle {
           display: none;
        }
        
        &__figure {
           display: none;
        }
    
        &__about {
            margin: 8rem auto 0 auto;
            width: 90vw;

           
    
            &__text{
                margin-left: 0rem;
                align-items: center;
                margin-bottom: 4rem;

                &--date {
                    border-radius: 15px;
                }

                &--link {
                    width: 20rem;
                    font-size: 14px;
                }
            }
            
    
            &__name {
                font-size: 30px;
                line-height: 30px;
                display: inline;
                
            }
    
            &__edit {
                top: 4rem;

                &--button {
                    width: 60px;
                    height: 60px;
                    top: 0;
                    left: 150%;
                }

                &--arr {
                    top: 2rem;
                    left: 150%;
                }

                &--text {
                    font-weight: 400;
                    font-size: 12px;
                    margin: 0 auto;
                }
            }
        }
    
        &__purse {
            width: 82vw;
            padding: 2rem;
            margin: 0 auto;
            height: 25rem;
    
            &__info {
                margin-bottom: 0;
                
                &--button {
                    font-size: 12px;
                    padding:1rem 2rem;
                    margin: 2rem 0;
                }
    
                &--picEye {
                    right: 5.6rem;
                    top: 4.1rem;
                }
    
                &--picWit {
                    top: 3.5rem;
                    right: 5rem;
                }
    
                &--picGrey {
                    top: 3.5rem;
                    right: 2rem;
                }
    
                &--picDark {
                    right: 3.5rem;
                    top: 3.5rem;
                    
                }
            }
    
            &__add {
                margin: 0 0;
    
            }
        }
    }

}

