@import "../../styles/style.scss";


.registration {

  &__form {

    &--head {
      font-family: 'Prosto One';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      text-transform: uppercase;
    }

    &--photoMobile {
      display: none;
    }

    &__layout {
      &__right{
     
      }
      &__middle {
        & p {
          color: $color;
        }
      }
    }
  }

  &__nav {
    position: absolute;
    top: 30px;
    left: 300px;
  }
}
.registration-text {
  grid-column: 1 / 2;
  width: 15rem;
}
.registration-input {
  grid-column: 3;
  width: 450px;
  margin-left: 8rem;
  background-color: #ffffff00;
  border: 1px solid #ffffff40;
  border-radius: 8px;
  margin-bottom: 2rem;
  height: 6rem;
  color: #FFF;
  font-size: 1.8rem;
  padding: 0 2.4rem;
}

.buttonConfirm {
  color: $color;
  background-image: url(../../../public/assets/icons/orangebutton.png);
  height: 60px;
  width: 450px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  font-family: 'Prosto One';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: left;
  padding-left: 2rem;
  margin-top: 5rem;
  margin-left: 23rem;

  &:hover {
    color: white;
  }
}

.arrButton {
  position: absolute;
  background-image: url(../../../public/assets/icons/arr.png);
  width: 37px;
  height: 18px;
  top: 7rem;
  left: 62rem;
}

.forget-info {
  position: absolute;
  left:24rem;
  top: 2rem;
  color: $color;
}
.forget-info a {
  text-decoration-color:$color;
  color: $color;
}

.bord {
  width: 68rem;
  color: $color;
  position: relative;
}

@media screen and (max-width:414px) {
  .registration {
    width: 100%;
    &__form {
      margin-top: 0;
      font-size: 32px;
    }
  
    &__nav {
      position: absolute;
      top: 30px;
      left: 300px;
    }

    &--photo {
      display: none;
    }

    &--photoMobile {
      position: absolute;
      background-image: url(../../../public/assets/image/minLogin.png);
      width: 253px;
      height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      left: 146px;
      top: 30px;
      z-index: -1;
    }
  }
  .registration-text {
    margin-left: 0.5rem;
  }

  .registration-input {
    margin-left: 0;
    grid-column: 1;
    width: 81vw;
  }

  .buttonConfirm {
    width: 95vw;
    margin-left: 0;
    border-radius: 10px;
  }
  
  .arrButton {
    width: 37px;
    height: 18px;
    top: 7rem;
    left: 27rem;
  }
  
  .forget-info {
    position: absolute;
    left: 1rem;
    top: 2rem;
    color: $color;
  }

  .bord {
    width: 68rem;
    color: $color;
    position: relative;
  }
}

@media screen and (min-width:415px) and (max-width: 820px){
  .registration {
    max-width: 98vw;
    &__form {
      margin-top: 0;
      font-size: 32px;
    }
  
    &__nav {
      position: absolute;
      top: 30px;
      left: 300px;
    }

    &--photo {
      display: none;
    }

    &--photoMobile {
      position: absolute;
      background-image: url(../../../public/assets/image/minLogin.png);
      width: 253px;
      height: 400px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
      left: 0;
      top: 30px;
      z-index: -1;
    }
  }
  .registration-text {
    margin-left: 0.5rem;
  }

  .registration-input {
    margin-left: 0;
    grid-column: 1;
    width: 50vw;
  }

  .buttonConfirm {
    width: 50vw;
    margin-left: 0;
    border-radius: 10px;
  }
  
  .arrButton {
    width: 37px;
    height: 18px;
    top: 7rem;
    left: 34rem;
  }
  
  .forget-info {
    position: absolute;
    left: 1rem;
    top: 2rem;
    color: $color;
  }

  .bord {
    width: 68rem;
    color: $color;
    position: relative;
  }
}